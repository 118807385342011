import './bootstrap';
import Alpine from 'alpinejs';
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://910d9726b7fdfa92807b92370521b978@o4507940421959680.ingest.de.sentry.io/4507940508139600",
  integrations: [],
});

window.Alpine = Alpine;

Alpine.start();

import.meta.glob([
    '../img/**',
    '../images/**',
    '../fonts/**',
]);
